import React from "react"
import styled from "@emotion/styled"
import ContentModules from "../components/content-modules"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import CategoryList from "../components/category-list/category-list"
import { NewsletterPopup } from "../components/newsletter-popup"
import Featured from "../components/featured/featured"

// MAIN CONTENT LAYOUT
const MainContentContainer = styled.div`
  ${({ theme }) => theme.bp.desktop} {
    display: flex;
  }
`

const MainContentLeftColumn = styled.div`
  ${({ theme }) => theme.bp.desktop} {
    width: 50%;
  }
`

const MainContentLeftColumnContent = styled.div`
  height: 100%;
`

const MainContentRightColumn = styled(
  /**
   * Right column
   *
   * @param {{
   *   highlighted?: boolean;
   *   className?: string;
   * }} props
   */
  ({ highlighted, ...props }) => (
    <div
      {...props}
      className={(highlighted ? "isHighlighted" : "") + " " + props.className}
    />
  )
)`
  background-color: ${(props) =>
    props.highlighted ? "#f56129" : "transparent"};

  ${({ theme }) => theme.bp.desktop} {
    width: 50%;
  }
`

const MainContentRightColumnContent = styled.div`
  height: 100%;
`

// SECTION HEADER
const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;

  ${({ theme }) => theme.bp.largeDesktop} {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const SectionHeaderTitle = styled.h4`
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  line-height: 1.5;

  ${({ theme }) => theme.bp.desktop} {
    font-size: ${({ theme }) => theme.fontSizes[2]}px;
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    font-size: ${({ theme }) => theme.fontSizes[3]}px;
  }
`

// FEATURED PRODUCTS
const FeaturedProductsLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: ${({ theme }) => theme.gap.betweenProducts[0]}px;
  padding: 0px ${({ theme }) => theme.gap.betweenProducts[0]}px;
  
  ${({ theme }) => theme.bp.largeDesktop} {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: ${({ theme }) => theme.gap.betweenProducts[1]}px;
    padding: 0px ${({ theme }) => theme.gap.betweenProducts[1]}px;
  }
`

const FeaturedProductsLayoutItem = styled.div`
  &:nth-child(5):last-child {
    grid-column: 1 / span 2;
    grid-row: 3 / span 2;
  }

  ${({ theme }) => theme.bp.largeDesktop} {
    &:nth-child(5):last-child {
      grid-column: 3 / span 2;
      grid-row: 1 / span 2;

      // Large pic - stretch product card
      > a {
        display: flex;
        flex-direction: column;
        height: 100%;

        > div:first-child {
          height: 100%;

          div {
            height: 100%;
          }
        }
      }
    }
  }
`

const Page = ({ data }) => {
  const { frontPage } = data

  const tileSections = frontPage?.contentModules.filter(
    (m) => m.internal?.type === "ContentfulTileSection"
  )

  const leftColumn = frontPage?.contentModules.filter((m) => m.isLeftColumn)
  const rightColumn = tileSections?.find(
    (tileSection) => tileSection.isRightColumn
  )
  const featuredCategories = tileSections?.find(
    (tileSection) => tileSection.isCategories
  )

  let categories = []
  if (featuredCategories?.tiles?.length) {
    categories = featuredCategories.tiles
      .filter(
        (e) =>
          e.reference?.internal?.type &&
          e.reference.internal.type === "ContentfulCategory"
      )
      ?.map((e) => {
        return {
          ...e.reference,
          thumbnail: e.backgroundImage?.id
            ? e.backgroundImage
            : e.reference.thumbnail,
        }
      })
  }

  const recommended = tileSections?.find((m) => m.isFeaturedProducts)
  let featuredProducts = []

  if (recommended?.tiles?.length) {
    featuredProducts = recommended.tiles
      .filter(
        (p) =>
          p.reference?.internal?.type &&
          p.reference.internal.type === "ContentfulProduct"
      )
      ?.map((p) => {
        let categories = p.reference.product_group?.flatMap((pg) =>
          pg.category?.flatMap((c) => c.slug)
        )
        let category = categories?.[0] ?? "all" // NOTE: Product should be at least in one category to have a valid url slug
        let slug = p.reference.handle

        return {
          ...p.reference,
          fullSlug: p.reference.fullSlug ?? category + "/" + slug,
          primaryImage: p.backgroundImage?.id
            ? p.backgroundImage
            : p.reference.primaryImage,
          featuredImage: p.backgroundImage?.id
            ? p.backgroundImage
            : p.reference.featuredImage,
        }
      })
  }

  const firstRightColumnTile = rightColumn?.tiles?.[0]

  console.log(firstRightColumnTile)

  return (
    <>
      <SEO />
      <MainContentContainer>
        {leftColumn?.[0]?.tiles?.[0]?.reference && (
          <MainContentLeftColumn>
            <MainContentLeftColumnContent>
              <ContentModules
                allModules={[leftColumn[0].tiles[0].reference]}
                options={{ position: "leftColumn" }}
              />
            </MainContentLeftColumnContent>
          </MainContentLeftColumn>
        )}
        <MainContentRightColumn highlighted={frontPage.isOrangeBackground}>
          <MainContentRightColumnContent>
            {firstRightColumnTile &&
              firstRightColumnTile.reference?.internal?.type &&
              firstRightColumnTile.reference.internal.type === "ContentfulStory" ? (
              <Featured input={{
                backgroundImage: firstRightColumnTile.backgroundImage,
                title: firstRightColumnTile.reference.title,
                titleSize: "32",
                titleColor: "#fff",
                cta: firstRightColumnTile.cta,
                linkTo: `/stories/${firstRightColumnTile.reference.slug}`,
                linkBackgroundColor: firstRightColumnTile.linkBackgroundColor,
                linkTextColor: firstRightColumnTile.linkTextColor
              }} />
            ) : <Featured input={{
              backgroundImage: firstRightColumnTile.backgroundImage,
              title: firstRightColumnTile.reference.title,
              titleSize: "32",
              titleColor: "#fff",
              cta: firstRightColumnTile.cta,
              linkTo: firstRightColumnTile.linkTo,
              linkBackgroundColor: firstRightColumnTile.linkBackgroundColor,
              linkTextColor: firstRightColumnTile.linkTextColor
            }} />}
          </MainContentRightColumnContent>
        </MainContentRightColumn>
      </MainContentContainer>

      {categories?.length ? (
        <CategoryList categories={categories} stacked></CategoryList>
      ) : null}

      {featuredProducts?.length ? (
        <>
          <SectionHeader>
            <SectionHeaderTitle>{recommended.title}</SectionHeaderTitle>
          </SectionHeader>
          <FeaturedProductsLayout>
            {(featuredProducts || []).map((product) => {
              return (
                <FeaturedProductsLayoutItem key={product.id}>
                  <ContentModules
                    allModules={[product]}
                    options={{ position: "featuredProducts" }}
                  />
                </FeaturedProductsLayoutItem>
              )
            })}
          </FeaturedProductsLayout>
        </>
      ) : null}
      <StoriesGrid stories={rightColumn?.tiles || []} />
      <NewsletterPopup isVisible={true} />
    </>
  )
}

export default Page

export const pageQuery = graphql`
  query IndexQuery {
    frontPage: contentfulPage(isHomepage: { eq: true }) {
      name
      isOrangeBackground
      contentModules {
        ...TileSection
      }
    }
  }
`
const StoriesGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 100%;
`

const StoriesGridItem = styled.div`
  width: 100%;
  height: 100%;
`

function StoriesGrid({ stories }) {
  return (
    <StoriesGridContainer>
      {stories?.slice(1, 5).map(story => (
        <StoriesGridItem key={story.reference.id}>
          <Featured input={{
            backgroundImage: story.backgroundImage,
            title: story.reference.title,
            titleSize: '20',
            titleColor: "#fff",
            cta: story.cta,
            linkTo: `/stories/${story.reference.slug}`,
            linkBackgroundColor: story.linkBackgroundColor,
            linkTextColor: story.linkTextColor
          }} />
        </StoriesGridItem>
      ))}
    </StoriesGridContainer>
  )
}
